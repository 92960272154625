import { createElement, FC } from 'react';
import {
  Body as RawBody,
  LinkBody as RawLinkBody,
  Button as RawButton,
  Disclosure as RawDisclosure,
  Eyebrow as RawEyebrow,
  FontContainer as RawFontContainer,
  LinkContainer as RawLinkContainer,
  Headline as RawHeadline,
  Helper as RawHelper,
  LinkHelper as RawLinkHelper,
  LeadBody as RawLeadBody,
  SectionHeaderOne as RawSectionHeaderOne,
  SectionHeaderTwo as RawSectionHeaderTwo,
  SmallLabel as RawSmallLabel,
  Title as RawTitle,
  Bold as RawBold,
  Italic as RawItalic,
  Superscript as RawSuperscript
} from './typography.ccm.css';
import { TypographyTheme, useTypographyTheme } from './typography.theme';

export const DefaultFonts =
  "-apple-system, BlinkMacSystemFont, Roboto, Arial, 'Helvetica Neue', sans-serif";
export const AreaFont = 'Area, ' + DefaultFonts;

export type TypographyColor = Exclude<keyof TypographyTheme, 'links'>;

const useTypographyColor = (color?: TypographyColor): string => {
  const theme = useTypographyTheme();
  return (color && theme[color]) || theme.neutral;
};

type ThemeableTypographyProps<Elt extends keyof JSX.IntrinsicElements> = Omit<
  JSX.IntrinsicElements[Elt],
  'ref' | 'key'
> & {
  color?: TypographyColor;
  fontFace?: typeof AreaFont | typeof DefaultFonts;
};

export const FontContainer: FC<ThemeableTypographyProps<'span'>> = ({
  color,
  fontFace = DefaultFonts,
  ...props
}) => (
  <RawFontContainer.span
    {...(props as any)}
    $color={useTypographyColor(color)}
    $fontFace={fontFace}
  />
);

export const LinkContainer: FC<
  JSX.IntrinsicElements['a'] & {
    fontFace?: typeof AreaFont | typeof DefaultFonts;
  }
> = ({ color, fontFace = DefaultFonts, ...props }) => {
  const {
    links: { active, inactive }
  } = useTypographyTheme();

  return (
    <RawLinkContainer.a
      {...(props as any)}
      $color={inactive}
      $activeColor={active}
      $fontFace={fontFace}
    />
  );
};

export const Headline: FC<ThemeableTypographyProps<'h1'>> = ({
  color,
  fontFace = DefaultFonts,
  ...props
}) => (
  <RawHeadline.h1
    {...(props as any)}
    $color={useTypographyColor(color)}
    $fontFace={fontFace}
  />
);

export const Title: FC<ThemeableTypographyProps<'h2'>> = ({
  color,
  fontFace = DefaultFonts,
  ...props
}) => (
  <RawTitle.h2
    {...(props as any)}
    $color={useTypographyColor(color)}
    $fontFace={fontFace}
  />
);

export const SectionHeaderOne: FC<ThemeableTypographyProps<'h3'>> = ({
  color,
  fontFace = DefaultFonts,
  ...props
}) => (
  <RawSectionHeaderOne.h3
    {...(props as any)}
    $color={useTypographyColor(color)}
    $fontFace={fontFace}
  />
);

export const SectionHeaderTwo: FC<ThemeableTypographyProps<'h5'>> = ({
  color,
  fontFace = DefaultFonts,
  ...props
}) => (
  <RawSectionHeaderTwo.h5
    {...(props as any)}
    $color={useTypographyColor(color)}
    $fontFace={fontFace}
  />
);

export const LeadBody: FC<ThemeableTypographyProps<'h4'>> = ({
  color,
  fontFace = DefaultFonts,
  ...props
}) => (
  <RawLeadBody.h4
    {...(props as any)}
    $color={useTypographyColor(color)}
    $fontFace={fontFace}
  />
);

export const Body: FC<ThemeableTypographyProps<'p'>> = ({
  color,
  fontFace = DefaultFonts,
  ...props
}) => (
  <RawBody.p
    {...(props as any)}
    $color={useTypographyColor(color)}
    $fontFace={fontFace}
  />
);

/**
 *
 * @deprecated
 * Use `LinkContainer` instead
 */
export const LinkBody: FC<
  JSX.IntrinsicElements['a'] & {
    fontFace?: typeof AreaFont | typeof DefaultFonts;
  }
> = ({ color, fontFace = DefaultFonts, ...props }) => {
  const {
    links: { active, inactive }
  } = useTypographyTheme();

  return (
    <RawLinkBody.a
      {...(props as any)}
      $color={inactive}
      $activeColor={active}
      $fontFace={fontFace}
    />
  );
};

export const Helper: FC<ThemeableTypographyProps<'p'>> = ({
  color,
  fontFace = DefaultFonts,
  ...props
}) => (
  <RawHelper.span
    {...(props as any)}
    $color={useTypographyColor(color)}
    $fontFace={fontFace}
  />
);

/**
 * @deprecated
 * Use `LinkContainer` instead
 */
export const LinkHelper: FC<
  JSX.IntrinsicElements['a'] & {
    fontFace?: typeof AreaFont | typeof DefaultFonts;
  }
> = ({ color, fontFace = DefaultFonts, ...props }) => {
  const {
    links: { active, inactive }
  } = useTypographyTheme();

  return (
    <RawLinkHelper.a
      {...(props as any)}
      $color={inactive}
      $activeColor={active}
      $fontFace={fontFace}
    />
  );
};

export const Disclosure: FC<ThemeableTypographyProps<'span'>> = ({
  color,
  fontFace = DefaultFonts,
  ...props
}) => (
  <RawDisclosure.span
    {...(props as any)}
    $color={useTypographyColor(color)}
    $fontFace={fontFace}
  />
);

export const SmallLabel: FC<ThemeableTypographyProps<'span'>> = ({
  color,
  fontFace = DefaultFonts,
  ...props
}) => (
  <RawSmallLabel.span
    {...(props as any)}
    $color={useTypographyColor(color)}
    $fontFace={fontFace}
  />
);

export const Button: FC<ThemeableTypographyProps<'span'>> = ({
  color,
  fontFace = DefaultFonts,
  ...props
}) => (
  <RawButton.span
    {...(props as any)}
    $color={useTypographyColor(color)}
    $fontFace={fontFace}
  />
);

export const Eyebrow: FC<ThemeableTypographyProps<'span'>> = ({
  color,
  fontFace = DefaultFonts,
  ...props
}) => (
  <RawEyebrow.span
    {...(props as any)}
    $color={useTypographyColor(color)}
    $fontFace={fontFace}
  />
);

/**
 * Use instead of <strong /> or <b /> or other styling.
 *
 * Use nested inside another Typography Component, not standalone
 *
 * ```
 *  <ContentBlock>
 *    <Body>
 *      <Bold>
 * ```
 */
export const Bold = RawBold.strong;

/**
 * Use instead of <em /> or <i /> or other styling.
 *
 * Use nested inside another Typography Component, not standalone
 *
 * ```
 *  <ContentBlock>
 *    <Body>
 *      <Italic>
 * ```
 */
export const Italic = RawItalic.em;

export const Superscript = RawSuperscript.sup;

export {
  RawFontContainer,
  RawHeadline,
  RawTitle,
  RawSectionHeaderOne,
  RawSectionHeaderTwo,
  RawLeadBody,
  RawBody,
  RawLinkBody,
  RawHelper,
  RawLinkHelper,
  RawDisclosure,
  RawSmallLabel,
  RawButton,
  RawEyebrow,
  RawSuperscript
};
