import { createUseThemeHook } from 'legos/theme';

declare module 'legos/theme' {
  interface BulkLegoTheme {
    typography: Partial<TypographyTheme>;
  }
}

export interface TypographyTheme {
  neutral: string;
  default: string;
  negative: string;
  special: string;
  white: string;
  links: {
    active: string;
    inactive: string;
  };
}

export const [TypographyThemeProvider, useTypographyTheme] = createUseThemeHook(
  'typography',
  (global): TypographyTheme => ({
    neutral: global.neutral.color800,
    default: global.default.color500,
    negative: global.negative.color500,
    special: global.special.color500,
    white: '#ffffff',
    links: {
      inactive: global.default.color500,
      active: global.default.color600
    }
  })
);
